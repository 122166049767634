
import LazyHydrate from 'vue-lazy-hydration'

export default {
  components: {
    LazyHydrate,
    'id-homepage-lazy-hero': () => import('~/components/homepage/lazy/Hero.vue'),
    'id-homepage-lazy-featured': () => import('~/components/homepage/lazy/Featured.vue'),
    'id-homepage-lazy-before-after': () => import('~/components/homepage/lazy/BeforeAfter.vue'),
    'id-homepage-lazy-categories': () => import('~/components/homepage/lazy/Categories.vue'),
    'id-homepage-lazy-testimonials': () => import('~/components/homepage/lazy/Testimonials.vue'),
    'id-homepage-lazy-trusted': () => import('~/components/homepage/lazy/Trusted.vue'),
    'id-homepage-lazy-works': () => import('~/components/homepage/lazy/Works.vue'),
    'id-homepage-lazy-benefits': () => import('~/components/homepage/lazy/Benefits.vue'),
    'id-homepage-lazy-newsletter': () => import('~/components/homepage/lazy/Newsletter.vue'),
    'id-homepage-lazy-about': () => import('~/components/homepage/lazy/About.vue'),
    'id-homepage-lazy-banner': () => import('~/components/homepage/lazy/Banner.vue'),
  },
  transition: 'page',
  head () {
    return {
      title: 'Stunning business presentations in no time. Powerful business slides and templates for powerpoint.',
      bodyAttrs: {
        class: 'page-home'
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Easy to use ppt business presentation templates for PowerPoint starting from $29. Immediate download of slides. Custom design services and presentation tips'
        },
        {
          hid: 'description',
          name: 'description',
          content: 'Editable slides compatible with PowerPoint, Keynote and Google Slides.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'powerpoint, slides, templates, presentations, keynote, google slides'
        }
      ]
    }
  },
  mounted () {
    this.$bing && this.$bing.event({ ecomm_pagetype: 'home' })
  }
}
